@import url(https://fastly.jsdelivr.net/npm/vant@4/lib/index.css);

*[data-v-5921a3c2]{
       font-family: "Helvetica Neue",helvetica,arial,sans-serif;
}
#login[data-v-5921a3c2]{
        background:#fff;
        background-size: cover;
}
#login .logo[data-v-5921a3c2]{
        height: 18vh;
        border-radius: 2vw;
}
#login .mask[data-v-5921a3c2]{
        height: 100vh;
        width: 100vw;
        background: rgba(0,0,0,0);
        position: absolute;
        z-index: 1;
}
.back[data-v-5921a3c2]{
        position: absolute;
        top: 0;
        z-index: 999;
        width: 13.3vw;
        height: 13.3vw;
        /* background: red; */
        display: flex;
        justify-content: center;
        align-items: center;
}
.back .icon[data-v-5921a3c2]{
        height: 16px;
}
.login-form[data-v-5921a3c2]{
        /* position: absolute; */
        padding:10px 30px 30px 30px;
        /* height: 100vh; */
        /* z-index: 2; */
}
.form-title[data-v-5921a3c2]{
        padding: 0px 0;
        font-size: 14px;
        color: #333;
        display: flex;
        flex-direction: column;
}
.form-title .title[data-v-5921a3c2]{
        font-weight: bold;
        font-size: 2rem;
        padding: 0px 0 30px 0;
        text-align: center;
}
.form-title .subtitle[data-v-5921a3c2]{
        font-size: 14px;
        color: #666;
        padding-left:10px;
}
.codebtn[data-v-5921a3c2]{
       width: 99px;
}
.form-input[data-v-5921a3c2]{
        outline-style: none;
        border: 1px solid #eee;
        border-radius:1.4rem;
        box-shadow: none;
        padding: 1.4rem 0;
        height: 1rem;
        width: 100%;
        color: #666;
        font-weight: 500;
        font-size: 1rem;
        text-indent: 1rem;
        -webkit-appearance: none;
        box-sizing: border-box;
}
.form-input[data-v-5921a3c2]:focus{
        border-color: #1989FA;
        outline: 0;
}
.form-group[data-v-5921a3c2]{
        width: 100%;
        padding: 2.0vh 0px;
        height: auto;
}
.form-control[data-v-5921a3c2]{
        font-size:14px;
        vertical-align: middle;
        color:#333;
        padding: 2.0vh;
        background: #fff;
}
.agreement[data-v-5921a3c2]{
    display: flex;
    align-items: center;
}
.agreement .text[data-v-5921a3c2]{
    font-size: 14px;
    margin-left:10px;
    color: #333;
}
.agreement .link[data-v-5921a3c2]{
    font-size: 14px;
    color: #1989FA;
    font-weight: bold;
}
.bt[data-v-5921a3c2] {
     display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #1989FA;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,.1);
    font-size: 14px;
    color: #fff;
    letter-spacing: 0;
    border-radius:2.8vh;
    text-align: center;
}
.code[data-v-5921a3c2]{
    border-radius: 0 2.8vh 2.8vh 0;
    width:140px;
    box-sizing: border-box;
    border: 1px solid #1989FA;
}
.btn[data-v-5921a3c2]{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1989FA;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
    font-size: 14px;
    font-weight: 700;
    padding: 2.0vh 0;
    color: #fff;
    letter-spacing: 0;
    border-radius: 1.0vh;
    text-align: center;
}
.bt.disabled[data-v-5921a3c2] {
    background-color: #d8d7d8;
    color: #989998
}
.pc-warpper[data-v-5921a3c2]{
}
.wave[data-v-5921a3c2]{
	position: fixed;
	bottom: 0;
	left: 0;
	height: 100%;
	z-index: 0;
}
.container[data-v-5921a3c2]{
    height: 95vh;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap :7rem;
    padding: 0 2rem;
}
.img[data-v-5921a3c2]{
	display: flex;
	justify-content: flex-end;
	align-items: center;
}
.img img[data-v-5921a3c2]{
    z-index: 1;
}
.login-content[data-v-5921a3c2]{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	text-align: center;
    z-index: 2;
}
.img img[data-v-5921a3c2]{
	width: 500px;
}
form[data-v-5921a3c2]{
	width: 360px;
}
.login-content img[data-v-5921a3c2]{
    height: 160px;
}
.login-content h2[data-v-5921a3c2]{
	margin: 15px 0;
	color: #333;
	text-transform: uppercase;
	font-size: 2.9rem;
}
.login-content .input-div[data-v-5921a3c2]{
	position: relative;
    display: grid;
    /* grid-template-columns: 7% 93%; */
    margin: 25px 0;
    padding: 5px 0;
    border-bottom: 2px solid #d9d9d9;
}
.login-content .input-div.one[data-v-5921a3c2]{
	margin-top: 0;
}
.i[data-v-5921a3c2]{
	color: #d9d9d9;
	display: flex;
	justify-content: center;
	align-items: center;
}
.i i[data-v-5921a3c2]{
	transition: .3s;
}
.input-div > div[data-v-5921a3c2]{
    position: relative;
	height: 45px;
    display: flex;
    flex-direction: row;
}
.input-div > div > h5[data-v-5921a3c2]{
	position: absolute;
	left: 10px;
	top: 0%;
	transform: translateY(-50%);
	color: #999;
	font-size: 18px;
	transition: .3s;
}
.input-div[data-v-5921a3c2]:before, .input-div[data-v-5921a3c2]:after{
	content: '';
	position: absolute;
	bottom: -2px;
	width: 0%;
	height: 2px;
	background-color: #38d39f;
	transition: .4s;
}
.input-div[data-v-5921a3c2]:before{
	right: 50%;
}
.input-div[data-v-5921a3c2]:after{
	left: 50%;
}
.input-div.focus[data-v-5921a3c2]:before, .input-div.focus[data-v-5921a3c2]:after{
	width: 50%;
}
.input-div.focus > div > h5[data-v-5921a3c2]{
	top: -5px;
	font-size: 15px;
}
.input-div.focus > .i > i[data-v-5921a3c2]{
	color: #38d39f;
}
.input-div > div > input[data-v-5921a3c2]{
	width: 100%;
	height: 100%;
	border: none;
	outline: none;
	background: none;
	padding: 0.5rem 0.7rem;
	font-size: 1.2rem;
	color: #555;
	font-family: 'poppins', sans-serif;
}
.input-div > div > input[data-v-5921a3c2]::-moz-placeholder{
    position: absolute;
	left: 10px;
	top: 50%;
	transform: translateY(-50%);
	color: #999;
	font-size: 18px;
	-moz-transition: .3s;
	transition: .3s;
}
.input-div > div > input[data-v-5921a3c2]::placeholder{
    position: absolute;
	left: 10px;
	top: 50%;
	transform: translateY(-50%);
	color: #999;
	font-size: 18px;
	transition: .3s;
}
.input-div.pass[data-v-5921a3c2]{
	margin-bottom: 4px;
}
a[data-v-5921a3c2]{
	display: block;
	text-align: right;
	text-decoration: none;
	color: #999;
	font-size: 0.9rem;
	transition: .3s;
}
a[data-v-5921a3c2]:hover{
	color: #38d39f;
}
.btn-pc[data-v-5921a3c2]{
	display: block;
	width: 100%;
	height: 50px;
	border-radius: 25px;
	outline: none;
	border: none;
	background-image: linear-gradient(to right, #32be8f, #38d39f, #32be8f);
	background-size: 200%;
	font-size: 1.2rem;
	color: #fff;
	font-family: 'Poppins', sans-serif;
	text-transform: uppercase;
	margin: 1rem 0;
	cursor: pointer;
	transition: .5s;
}
.btn-pc-mini[data-v-5921a3c2]{
    height: 30px;
    border-radius: 15px;
    width: 150px;
    font-size: 14px;
}
.btn-pc[data-v-5921a3c2]:hover{
	background-position: right;
}
@media screen and (max-width: 1050px){
.container[data-v-5921a3c2]{
		grid-gap: 5rem;
}
}
@media screen and (max-width: 1000px){
form[data-v-5921a3c2]{
		width: 290px;
}
.login-content h2[data-v-5921a3c2]{
        font-size: 2.4rem;
        margin: 8px 0;
}
.img img[data-v-5921a3c2]{
		width: 400px;
}
}
@media screen and (max-width: 900px){
.container[data-v-5921a3c2]{
		grid-template-columns: 1fr;
}
.img[data-v-5921a3c2]{
		display: none;
}
.wave[data-v-5921a3c2]{
		display: none;
}
.login-content[data-v-5921a3c2]{
		justify-content: center;
}
}



/*# sourceMappingURL=chunk-09526324.e8c4eb23.css.map*/